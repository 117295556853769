import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../../components/PageWrapper";
import { Select } from "../../components/Core";
import RichTextEditor from "../../components/RichTextEditor";
import { AuthContext } from "../../context/AuthContext";
import { DataContext } from "../../context/DataContext";
import { validation } from "../../utils/validation";
import notify from "../../utils/notify";
import { updateJob, fetchJobDetails } from "../../api";
import CustomDatePicker from "../../components/DatePicker";

const JobDetailsEdit = ({ location, params }) => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [job, setJob] = useState(null);
  const currentDate = new Date();
  const defaultExpiryDate = currentDate.setMonth(currentDate.getMonth() + 3)
  const [expiryDate, setExpiryDate] = useState(parseInt(defaultExpiryDate))

  const expiryDateChange = (data) => {
    setExpiryDate(data);
    setValue("expiryDate", data);
  };

  const {
    register,
    errors,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
  } = useForm();

  const watchJobType = watch("jobtype");
  const watchCategory = watch("category");
  const { auth } = useContext(AuthContext);
  const {
    categories,
    currencies,
    jobTypes,
    experienceLevels,
    allSkills,
    getCategories,
    getSkills,
  } = useContext(DataContext);
  const param = params[`*`];

  const loadSettings = async () => {
    await getCategories();
    await getSkills();
  };

  const getJobDetails = async (jobId) => {
    const details = await fetchJobDetails(jobId);
    await loadSettings();
    if (details?.expiryDate) {
      setExpiryDate(details.expiryDate)
    }
    setJob(details);
    setLoading(false);
  };

  useEffect(() => {
    if (!auth || auth?.talent) {
      navigate("/", { replace: true });
    }
    const jobId = param;
    getJobDetails(jobId);
  }, []);

  const onEditorStateChange = (data) => {
    setValue("description", data);
  };

  const onSubmit = async (data) => {
    try {
      setSubmitting(true);
      const category = data.category.label;
      const subcategory = data.subcategory.label;
      const experience = data.experience.label;
      const currency = data?.currency?.label;
      const jobtype = data.jobtype.label;
      const skills = data.skills.map(({ label }) => label);
      const rate = data?.rate ? parseInt(data.rate) : null;
      const salary = data?.salary ? parseInt(data.salary) : null;
      const newExpiryDate = data?.expiryDate
        ? parseInt(new Date(data.expiryDate).getTime())
        : parseInt(expiryDate.getTime())

      const formData = _.omit(data, [
        "category",
        "subcategory",
        "experience",
        "skills",
        "rate",
        "salary",
        "currency",
        "terms",
        "jobtype",
        "expiryDate"
      ]);
      const payload = {
        category,
        subcategory,
        experience,
        skills,
        rate: jobtype === "Freelancing" ? rate : null,
        salary: jobtype === "Full Time" ? salary : null,
        currency,
        jobtype,
        expiryDate: newExpiryDate,
        ...formData,
      };
      reset();
      await updateJob(job.id, "client", payload);
      notify("Job updated successfully!", "success");
      setTimeout(
        () => navigate(`/job-details/${job.id}`, { state: { id: job.id } }),
        1000
      );

    } catch (error) {
      console.log(error);
      notify(error.message);
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageWrapper headerConfig={auth ? { button: "profile" } : null}>
        <div className="bg-default-2 pt-22 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 dark-mode-texts">
                <div className="mb-9">
                  {loading ? (
                    <Skeleton
                      height={50}
                      containerClassName="w-40"
                      className="w-40"
                    />
                  ) : (
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                      }}
                      className="d-flex align-items-center ml-4"
                    >
                      {" "}
                      <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                      <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                        Back
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12">
                {loading ? (
                  <Skeleton
                    height={100}
                    containerClassName="text-center w-100"
                    className="w-70"
                  />
                ) : (
                  <h3 className="card-title font-size-8 font-weight-bold text-center">
                    Update {job?.position}
                  </h3>
                )}
              </div>
            </div>

            <div className="row justify-content-center pt-12">
              <div className="col-12 col-xxl-7 col-lg-7 col-md-7">
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="position"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Position*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={job?.position}
                        id="position"
                        name="position"
                        ref={register(validation.position)}
                      />
                      <span className="text-danger small">
                        {errors?.position?.message || ""}
                      </span>
                    </div>
                  )}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="company"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Company Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={job?.company}
                        id="company"
                        name="company"
                        ref={register(validation.company)}
                      />
                      <span className="text-danger small">
                        {errors?.company?.message || ""}
                      </span>
                    </div>
                  )}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={400}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="description"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Description*
                      </label>
                      <Controller
                        name="description"
                        control={control}
                        defaultValue=""
                        rules={validation.description}
                        as={
                          <RichTextEditor
                            onEditorStateChange={onEditorStateChange}
                            initialValue={job.description}
                            name="description"
                          />
                        }
                      />
                      <span className="text-danger small">
                        {errors?.description?.message || ""}
                      </span>
                    </div>
                  )}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="category"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Category*
                      </label>
                      <Controller
                        name="category"
                        control={control}
                        defaultValue={
                          categories[
                          categories.findIndex(
                            ({ label }) => label === job.category
                          )
                          ]
                        }
                        rules={validation.category}
                        as={
                          <Select
                            options={categories}
                            className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                            border={false}
                          />
                        }
                      />
                      <span className="text-danger small">
                        {errors?.category?.message || ""}
                      </span>
                    </div>
                  )}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (

                    <div className="form-group">
                      <label
                        htmlFor="subcategory"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Subcategory*
                      </label>
                      <Controller
                        name="subcategory"
                        control={control}
                        defaultValue={
                          categories[
                            categories.findIndex(
                              (category) => category.label === job.category
                            )
                          ].subcategories[
                          categories[
                            categories.findIndex(
                              (category) => category.label === job.category
                            )
                          ].subcategories.findIndex(
                            ({ label }) => label === job.subcategory
                          )
                          ]
                        }
                        rules={validation.subcategory}
                        as={
                          <Select
                            options={
                              watchCategory?.value
                                ? categories.filter((category) => {
                                  if (
                                    category.value === watchCategory?.value
                                  ) {
                                    return category.subcategories;
                                  }
                                })[0].subcategories
                                : []
                            }
                            className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                            border={false}
                          />
                        }
                      />
                      <span className="text-danger small">
                        {errors?.subcategory?.message || ""}
                      </span>
                    </div>

                  )}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="otherCategory"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Other Catergory (Optional)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={job?.otherCategory}
                        id="otherCategory"
                        name="otherCategory"
                        ref={register(validation.otherCategory)}
                      />
                      <span className="text-danger small">
                        {errors?.otherCategory?.message || ""}
                      </span>
                    </div>
                  )}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="jobtype"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Job Type*
                      </label>
                      <Controller
                        name="jobtype"
                        control={control}
                        defaultValue={
                          jobTypes[
                          jobTypes.findIndex(
                            ({ label }) => label === job.jobtype
                          )
                          ]
                        }
                        rules={validation.jobtype}
                        as={
                          <Select
                            options={jobTypes}
                            className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                            border={false}
                            id="jobtype"
                          />
                        }
                      />
                      <span className="text-danger small">
                        {errors?.jobtype?.message || ""}
                      </span>
                    </div>
                  )}

                  {loading ? (
                    <Skeleton
                      height={100}
                      containerClassName="text-center w-100"
                      className="w-70"
                    />
                  ) : (
                    <h3 className="card-title font-size-6 font-weight-bold">
                      Talent Preference
                    </h3>
                  )}
                  <hr />

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : ["Full Time", "full-time"].includes(
                    watchJobType?.value
                  ) || job.jobtype === "Full Time" ? (
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="form-group">
                        <label
                          htmlFor="salary"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Salary*
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="salary"
                          name="salary"
                          defaultValue={job.salary}
                          ref={register(validation.salary)}
                        />
                        <span className="text-danger small">
                          {errors?.salary?.message || ""}
                        </span>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="currency"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Currency*
                        </label>
                        <Controller
                          name="currency"
                          control={control}
                          defaultValue={
                            currencies[
                            currencies.findIndex(
                              ({ label }) => label === job.currency
                            )
                            ]
                          }
                          rules={validation.currency}
                          as={
                            <Select
                              options={currencies}
                              className="pl-0 arrow-3 arrow-3-black min-width-px-150 text-black-2 d-flex align-items-center form-control"
                              border={false}
                              id="currency"
                            />
                          }
                        />
                        <span className="text-danger small">
                          {errors?.currency?.message || ""}
                        </span>
                      </div>
                    </div>
                  ) : ["Freelancing", "freelancing"].includes(
                    watchJobType?.value
                  ) || job.jobtype === "Freelancing" ? (
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="form-group">
                        <label
                          htmlFor="rate"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Hourly Rate*
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="rate"
                          name="rate"
                          defaultValue={job.rate}
                          ref={register(validation.rate)}
                        />
                        <span className="text-danger small">
                          {errors?.rate?.message || ""}
                        </span>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="currency"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Currency*
                        </label>
                        <Controller
                          name="currency"
                          control={control}
                          defaultValue={
                            currencies[
                            currencies.findIndex(
                              ({ label }) => label === job.currency
                            )
                            ]
                          }
                          rules={validation.currency}
                          as={
                            <Select
                              options={currencies}
                              className="pl-0 arrow-3 arrow-3-black min-width-px-150 text-black-2 d-flex align-items-center form-control"
                              border={false}
                              id="currency"
                            />
                          }
                        />
                        <span className="text-danger small">
                          {errors?.currency?.message || ""}
                        </span>
                      </div>
                    </div>
                  ) : null}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="experience"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Experience Level*
                      </label>
                      <Controller
                        name="experience"
                        control={control}
                        defaultValue={
                          experienceLevels[
                          experienceLevels.findIndex(
                            ({ label }) => label === job.experience
                          )
                          ]
                        }
                        rules={validation.experience}
                        as={
                          <Select
                            options={experienceLevels}
                            className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                            border={false}
                            id="experience"
                          />
                        }
                      />
                      <span className="text-danger small">
                        {errors?.experience?.message || ""}
                      </span>
                    </div>
                  )}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="skills"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Skills Needed*
                      </label>
                      <Controller
                        name="skills"
                        as={
                          <Select
                            options={allSkills}
                            className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control basic-multi-select"
                            classNamePrefix="select"
                            border={false}
                            isMulti
                          />
                        }
                        control={control}
                        defaultValue={allSkills.filter((skill) =>
                          job.skills.includes(skill.label)
                        )}
                        rules={validation.skills}
                      />
                      <span className="text-danger small">
                        {errors?.skills?.message || ""}
                      </span>
                    </div>
                  )}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="location"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Location*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="location"
                        name="location"
                        defaultValue={job.location}
                        ref={register(validation.location)}
                      />
                      <span className="text-danger small">
                        {errors?.location?.message || ""}
                      </span>
                    </div>
                  )}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (auth?.admin || auth?.client) ? (
                    <div className="form-row justify-content-between">
                      <div className="col">
                        <label
                          htmlFor="expiryDate"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Expiry Date
                        </label>
                        <Controller
                          name="expiryDate"
                          control={control}
                          defaultValue={expiryDate}
                          rules={validation.expiryDate}
                          as={
                            <CustomDatePicker
                              placeholder="MM-yyyy"
                              className="form-control"
                              initialvalue={expiryDate}
                              name="expiryDate"
                              dateChange={expiryDateChange}
                              selected={expiryDate}
                              disabledDays={false}
                            />
                          }
                        />

                        <span className="text-danger small">
                          {errors?.expiryDate?.message || ""}
                        </span>
                      </div>
                    </div>
                  ) : null}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : auth?.admin ? (
                    <div className="form-group">
                      <label
                        htmlFor="applicationurl"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        External Application URL
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={job?.applicationurl || ""}
                        id="applicationurl"
                        name="applicationurl"
                        ref={register(validation.url)}
                      />
                      <span className="text-danger small">
                        {errors?.applicationurl?.message || ""}
                      </span>
                    </div>
                  ) : null}

                  <div className="form-group mb-8">
                    {loading ? (
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase focus-reset"
                        disabled={submitting || loading ? true : false}
                      >
                        {submitting ? "Updating..." : "Update"}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default JobDetailsEdit;
